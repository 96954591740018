/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";

import classNames from "classnames";
import useLocalStorageState from "use-local-storage-state";
import { useLocation } from "@reach/router";

import Button from "@components/common/Button";

const Banner = () => {
  const [hasWindow, setHasWindow] = useState(false);
  const [bannerIsOpen, setBannerIsOpen] = useLocalStorageState<boolean>("isOpenPromoCodeBanner", {
    defaultValue: true,
  });
  const { pathname } = useLocation();

  // const bannerText =
  //   process.env.GATSBY_ANNOUNCEMENT_BANNER ||
  //   '<span class="bold">GET FREE FLOWER</span>: Subscribe & Get Free Flower + Free Shipping Every Month!';

  const bannerText =
    "<span class='promo-description'>🎄Christmas Sale: So Frosty, Even Santa’s Jealous! Get <span class='bold'>Free Gifts + Free Shipping! ❄️</span></span>";

  useEffect(() => {
    setHasWindow(true);
  }, []);

  useEffect(() => {
    if (pathname.includes("/shop")) {
      setBannerIsOpen(false);
    }
  }, [pathname]);

  const handleCloseBanner = (): void => {
    setBannerIsOpen(!bannerIsOpen);
  };

  return (
    <div className="banner-container">
      <div
        className={classNames("banner", {
          showed: !!bannerText && bannerIsOpen && hasWindow,
        })}
      >
        <div
          className="banner-info"
          dangerouslySetInnerHTML={{
            __html: bannerText || "",
          }}
        />

        <Button
          name="close-banner"
          icon="cross"
          color="link-secondary"
          onClick={handleCloseBanner}
          aria-label="close-banner"
        />
      </div>
    </div>
  );
};

export default Banner;
