/* eslint-disable prefer-destructuring */
import { AppThunk } from "@store/index";

import type { UpdateItemsQuantitiesPayload } from "../types/cartSliceState";

import { areCartItemsEqual } from "../utils/common/areCartItemsEqual";

import { addToCart, removeFromCart } from "../index";

export const updateItemsQuantities =
  (payload: UpdateItemsQuantitiesPayload): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const cartItems = state.cart.cartItems;
    const updatedCartItems = payload.cartItems;
    const isRemoveProgressProduct = payload?.isRemoveProgressProduct;

    updatedCartItems.forEach((updatedCartItem) => {
      const existingItem = cartItems.find((cartItem) =>
        areCartItemsEqual(cartItem, updatedCartItem)
      );

      if (existingItem) {
        if (updatedCartItem.quantity < existingItem.quantity) {
          const updatedItem = {
            ...existingItem,
            quantity: existingItem.quantity - updatedCartItem.quantity,
          };

          dispatch(removeFromCart({ cartItems: [updatedItem], isRemoveProgressProduct }));
        } else if (updatedCartItem.quantity > existingItem.quantity) {
          const updatedItem = {
            ...existingItem,
            quantity: updatedCartItem.quantity - existingItem.quantity,
          };

          dispatch(addToCart({ cartItems: [updatedItem] }));
        }
      } else {
        dispatch(addToCart({ cartItems: [{ ...updatedCartItem, quantity: 1 }] }));
      }
    });
  };
