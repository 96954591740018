import type { CartItem } from "@typings/cart";

import { areCartItemsEqual } from "../common/areCartItemsEqual";

export const removeCartItems = (
  cartItems: CartItem[],
  removedCartItems: CartItem[],
  isRemoveProgressProduct = false
) => {
  const updatedCartItems = cartItems
    .map((item) => {
      const removedItem = removedCartItems.find((ri) => areCartItemsEqual(ri, item));

      if (!removedItem) return item;

      const newQuantity = item.quantity - removedItem.quantity;

      if (newQuantity <= 0) return null;

      return {
        ...item,
        quantity: newQuantity,
      };
    })
    .filter(Boolean);

  const hasMainProduct = updatedCartItems.some((item) =>
    ["EDIBLE", "FLOWER"].includes(item?.product.type)
  );
  const hasSubscriptionProduct = updatedCartItems.some(
    (item) => item?.product.subscriptionType !== "NONE" && !item?.isFree
  );

  const hasProgressFreeProducts = updatedCartItems.some((item) =>
    ["NugFree", "PreRollFree"].includes(item?.product.sku)
  );

  if (!hasMainProduct || !hasSubscriptionProduct) {
    if (!hasProgressFreeProducts || isRemoveProgressProduct) {
      return updatedCartItems.filter((item) => !item?.isFree);
    }
  }

  return updatedCartItems;
};
