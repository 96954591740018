import React from "react";
import progress1 from "src/images/progress-bar/1.png";
import progress2 from "src/images/progress-bar/2.png";
import progress3 from "src/images/progress-bar/3.png";
import progress4 from "src/images/progress-bar/4.png";

interface Step {
  label: string;
  image: string;
  title?: string;
  className?: string;
}

const steps: Step[] = [
  {
    label: "Empty cart",
    image: progress1,
    className: "adjusted-image",
    title: "Free Shipping",
  },
  {
    label: "Free shipping",
    image: progress2,
    className: "adjusted-image2",
    title: "Free THCa Flower",
  },
  {
    label: "Free THCa flower",
    image: progress3,
    title: "Free THCa Pre-Rolls",
  },
  {
    label: "Free THCa pre-rolls",
    image: progress4,
    className: "adjusted-image3",
  },
];

interface ProgressBarProps {
  currentStep: number;
  remainingAmount: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, remainingAmount }) => {
  const currentTitle = steps[currentStep]?.title;

  return (
    <div className="progress-container" role="progressbar" aria-valuenow={currentStep}>
      {currentTitle && (
        <div className="progress-title">
          <p>{`You're $${remainingAmount} away from`}</p>
          <span>{currentTitle}</span>
        </div>
      )}

      <div className="image-slider">
        {steps.map((step, index) => (
          <img
            key={index}
            src={step.image}
            alt={step.label}
            className={`step-image ${index === currentStep ? "visible" : ""} ${
              step.className || ""
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
